const serviceCategories = [
  {
    id: 'renovari-interioare',
    name: 'Renovări Interioare',
    description: 'Servicii complete de renovări interioare, inclusiv zugrăvit, tencuială și montaj parchet.',
    subcategories: [
      { id: 'zugravit', name: 'Zugrăvit', description: 'Servicii profesionale de zugrăvit pentru orice tip de încăpere.' },
      { id: 'tencuiala', name: 'Tencuială', description: 'Tencuieli de calitate pentru un finisaj perfect.' },
      { id: 'gresie-faianta', name: 'Montaj gresie și faianță', description: 'Montaj gresie și faianță pentru băi, bucătării și alte spații.' },
      { id: 'parchet', name: 'Montaj parchet', description: 'Montaj parchet din diverse tipuri de lemn pentru un aspect elegant.' },
      { id: 'raschetare-parchet', name: 'Rașchetare parchet', description: 'Servicii profesionale de rașchetare și recondiționare parchet.' },
      { id: 'reconditionare-parchet', name: 'Recondiționare parchet', description: 'Recondiționare completă a parchetului, inclusiv lăcuire și finisare.' },
      { id: 'montaj-usi', name: 'Montaj uși', description: 'Montaj profesional de uși interioare și exterioare.' },
      { id: 'reparatii-usi', name: 'Reparații uși', description: 'Servicii de reparații pentru uși deteriorate sau defecte.' },
      { id: 'reconditionare-usi', name: 'Recondiționare uși', description: 'Recondiționare completă a ușilor, inclusiv rașchetare și lăcuire.' },
      { id: 'tamplar', name: 'Tâmplărie', description: 'Servicii de tâmplărie pentru feronerie și uși de interior.' },
      { id: 'rigips', name: 'Montaj rigips', description: 'Montaj rigips pentru tavane și pereți cu finisaje perfecte.' },
      { id: 'izolatie-termica', name: 'Izolație termică', description: 'Izolație termică eficientă pentru economisirea energiei.' },
      { id: 'vopsitorie', name: 'Vopsitorie decorativă', description: 'Vopsitorie decorativă pentru un finisaj estetic și durabil.' },
      { id: 'tavane-false', name: 'Montaj tavane false', description: 'Instalare tavane false pentru un aspect modern și funcțional.' },
      { id: 'amenajari-poduri', name: 'Amenajări poduri', description: 'Amenajări funcționale și estetice ale podurilor din locuințe.' },
      { id: 'montaj-ferestre', name: 'Montaj feronerie feronerie', description: 'Instalarea feroneriei pentru feronerie de calitate.' }
    ]
  },
  {
    id: 'instalatii',
    name: 'Instalații',
    description: 'Instalații electrice, sanitare, termice și altele pentru un confort maxim în locuința ta.',
    subcategories: [
      { id: 'electrice', name: 'Instalații electrice', description: 'Instalații electrice de calitate pentru siguranță și performanță.' },
      { id: 'sanitare', name: 'Instalații sanitare', description: 'Instalații sanitare pentru băi și bucătării, cu tehnologie modernă.' },
      { id: 'termice', name: 'Instalații termice', description: 'Instalații termice pentru încălzire eficientă și ecologică.' },
      { id: 'gaz', name: 'Instalații de gaz', description: 'Instalații de gaz sigure și eficiente pentru locuința ta.' },
      { id: 'ventilatie', name: 'Sisteme de ventilație', description: 'Sisteme de ventilație pentru un aer curat și sănătos în interior.' },
      { id: 'aer-conditionat', name: 'Montaj aer condiționat', description: 'Instalare și montaj aer condiționat pentru confortul casei tale.' },
      { id: 'panouri-solare', name: 'Instalare panouri solare', description: 'Instalare panouri solare pentru economisirea energiei.' },
      { id: 'pompe-caldura', name: 'Instalare pompe de căldură', description: 'Pompe de căldură eficiente pentru o încălzire sustenabilă.' },
      { id: 'sisteme-incalzire', name: 'Sisteme de încălzire în pardoseală', description: 'Sisteme de încălzire în pardoseală pentru confort sporit și economii de energie.' },
      { id: 'automatizari', name: 'Automatizări pentru casă', description: 'Sisteme de automatizare pentru o locuință inteligentă și eficientă.' }
    ]
  },
  {
    id: 'constructii',
    name: 'Construcții',
    description: 'Construcții de orice tip, de la fundații la structuri metalice și case din lemn.',
    subcategories: [
      { id: 'zidarie', name: 'Zidărie', description: 'Servicii de zidărie pentru orice tip de construcție, de la locuințe la clădiri comerciale.' },
      { id: 'fundatii', name: 'Fundații', description: 'Fundații solide pentru construcții durabile.' },
      { id: 'acoperisuri', name: 'Acoperișuri', description: 'Instalare acoperișuri sigure și durabile.' },
      { id: 'structuri-metalice', name: 'Structuri metalice', description: 'Construcția structurilor metalice pentru clădiri industriale și comerciale.' },
      { id: 'consolidari', name: 'Consolidări clădiri', description: 'Servicii de consolidare pentru clădiri vechi sau deteriorate.' },
      { id: 'demolari', name: 'Demolări controlate', description: 'Demolări controlate pentru terenuri pregătite pentru construcții noi.' },
      { id: 'case-lemn', name: 'Construcții case din lemn', description: 'Case din lemn construite cu tehnici moderne și durabile.' },
      { id: 'case-pasive', name: 'Construcții case pasive', description: 'Construcția de case pasive eficiente energetic și ecologice.' },
      { id: 'izolatii-hidro', name: 'Izolații hidroizolante', description: 'Izolații eficiente pentru protejarea clădirilor de infiltrațiile de apă.' },
      { id: 'constructii-industriale', name: 'Construcții industriale', description: 'Construcția de clădiri industriale și hale de producție.' }
    ]
  },
  {
    id: 'exterioare',
    name: 'Amenajări Exterioare',
    description: 'Amenajări exterioare pentru a transforma spațiile verzi și a le face mai funcționale.',
    subcategories: [
      { id: 'gradinarit', name: 'Grădinărit', description: 'Servicii complete de grădinărit pentru un spațiu verde bine întreținut.' },
      { id: 'piscine', name: 'Construcție și întreținere piscine', description: 'Construcția și întreținerea piscinelor pentru relaxare și confort.' },
      { id: 'peisagistica', name: 'Amenajare peisagistică', description: 'Amenajare peisagistică pentru grădini și curți.' },
      { id: 'pavaje', name: 'Pavaje și alei', description: 'Montaj pavaje și alei pentru grădini și zone de acces.' },
      { id: 'terase', name: 'Construcție terase', description: 'Construcția de terase pentru un spațiu exterior de relaxare.' },
      { id: 'garduri', name: 'Montaj garduri și porți', description: 'Montaj garduri și porți pentru protecție și confidențialitate.' },
      { id: 'irigatie', name: 'Sisteme de irigație', description: 'Instalare sisteme de irigație eficiente pentru grădini și terenuri agricole.' },
      { id: 'iluminat-exterior', name: 'Iluminat exterior', description: 'Instalare iluminat exterior pentru siguranță și estetică.' },
      { id: 'foisoare', name: 'Construcție foisoare și pergole', description: 'Construcția de foisoare și pergole pentru relaxare în aer liber.' },
      { id: 'drenaj-teren', name: 'Drenaj teren', description: 'Instalare sisteme de drenaj pentru protejarea terenurilor de apă.' },
      
      
    ]
  },
  {
    id: 'servicii-specializate',
    name: 'Servicii Specializate',
    description: 'Servicii specializate pentru diverse nevoi, de la proiectare la curățenie profesională.',
    subcategories: [
      { id: 'proiectare', name: 'Servicii de proiectare', description: 'Servicii de proiectare pentru construcții și renovări.' },
      { id: 'consultanta', name: 'Consultanță în construcții', description: 'Consultanță profesională în domeniul construcțiilor și al renovărilor.' },
      { id: 'curatenie', name: 'Curățenie profesională', description: 'Servicii de curățenie pentru case, birouri și locații comerciale.' },
      { id: 'verificare-termografica', name: 'Verificare termografică', description: 'Verificare termografică pentru identificarea pierderilor de căldură.' },
      { id: 'inspectii-tehnice', name: 'Inspecții tehnice', description: 'Servicii de inspecție pentru evaluarea stării construcțiilor.' },
      {
        id: "expertize-tehnice",
        name: "Expertize tehnice",
        description: "Expertize tehnice pentru a păstra construcțiile in stare buna."
      },
      {
        id: "topografie",
        name: "Topografie",
        description: "Topografie pentru a păstra terenurile in stare buna."
      },

      {
        id: "restaurari",
        name: "Restaurări",
        description: "Restaurări clădiri pentru a păstra clădirile in stare buna."
      },
      {
        id: "audit-energetic",
        name: "Audit energetic",
        description: "Audit energetic pentru a păstra clădirile in stare buna."
      },
      
    ]
  },
  {
    id: 'finisaje',
    name: 'Finisaje',
    description: 'Servicii de finisaje pentru a da un aspect profesional fiecărui proiect.',
    subcategories: [
      { id: 'zugraveala', name: 'Zugrăveală', description: 'Zugrăveli interioare și exterioare de calitate.' },
      { id: 'tapet', name: 'Aplicare tapet', description: 'Servicii de aplicare tapet pentru un decor elegant.' },
      { id: 'vopsitorii-decorative', name: 'Vopsitorii decorative', description: 'Vopsitorii decorative pentru crearea unui ambient personalizat.' },
      { id: 'decorativa-interior', name: 'Decorativă interior', description: 'Aplicare tencuială decorativă pentru interior.' },
      { id: 'decorativa-exterior', name: 'Decorativă exterior', description: 'Aplicare tencuială decorativă pentru exterior.' },
      { id: 'finisaje-garduri', name: 'Finisaje garduri', description: 'Finisaje și decorative pentru garduri și împrejmuiri.' },
      { id: 'placaje-marmura', name: 'Placaje din marmură', description: 'Instalare placaje din marmură pentru un finisaj rafinat.' },
      { id: 'parchet', name: 'Montaj parchet', description: 'Montaj parchet din lemn masiv sau laminat.' },
      {
        id: 'profile-decorative',
        name: "Profile decorative",
        description: "Profile decorative pentru a păstra pereții in stare buna."
      },
      {
        id: 'scari',
        name: "Scări",
        description: "Servicii profesionale de reparații și întreținere pentru scări, asigurând siguranță și durabilitate."
      },
      {
        id: "sticla",
        name: "Sticlă",
        description: "Montaj, reparații și întreținere pentru sticlă, oferind soluții durabile și estetice pentru orice proiect."
      },
      {
        id: "sape",
        name: "Șape",
        description: "Servicii profesionale de turnare șape pentru fundații și podele, asigurând o bază solidă și uniformă pentru orice tip de finisaj."
      },
      {
        id:"balustrade",
        name: "Balustrade din Lemn",
        description: "Balustrade din lemn personalizate pentru o estetică naturală și siguranță sporită în orice locuință sau spațiu comercial."
      },
      {
        id: "placari-exterioare",
        name: "Placări Exterioare",
        description: "Servicii de placare exterioară pentru protecția și estetica fațadelor, utilizând materiale de calitate superioară pentru durabilitate maximă."
      },
      {
        id: "mocheta",
        name: "Mocheta",
        description: "Instalare și întreținere mochete de calitate pentru birouri, locuințe sau spații comerciale, oferind confort și estetică pe termen lung."
      },
      {
        id: 'pardoseli-epoxidice',
        name: "Pardoseli Epoxidice",
        description: "Aplicare pardoseli epoxidice pentru medii industriale sau comerciale, asigurând o suprafață rezistentă și ușor de întreținut."
      },
      {
        id: "piatra-naturala",
        name: "Piatra Naturală",
        description: "Montaj de piatră naturală pentru interioare și exterioare, aducând un aspect elegant și durabil în orice proiect de construcție."
      }, 
      {
        id: "placari-interioare",
        name: "Placări Interioare",
        description: "Servicii de placare interioară pentru un aspect estetic și funcțional al camerelor, folosind materiale de înaltă calitate pentru durabilitate."
      }
      

      
    ]
  },
  {
    id: 'reparatii-intretinere',
    name: 'Reparații și Întreținere',
    description: 'Servicii de reparații și întreținere pentru case și apartamente.',
    subcategories: [
      { id: 'reparatii-electrice', name: 'Reparații electrice', description: 'Reparații electrice rapide și sigure pentru locuința ta.' },
      { id: 'reparatii-sanitare', name: 'Reparații sanitare', description: 'Reparații sanitare pentru instalații și echipamente defecte.' },
      { id: 'reparatii-structura', name: 'Reparații structurale', description: 'Reparații structurale pentru clădiri și locuințe deteriorate.' },
      { id: 'reparatii-acoperisuri', name: 'Reparații acoperișuri', description: 'Reparații acoperișuri pentru protecția locuinței tale.' },
      { id: 'intretinere-gradina', name: 'Întreținere grădină', description: 'Întreținere periodică a grădinii pentru un aspect mereu îngrijit.' },
      { id: 'reparatii-termopane', name: 'Reparații termopane', description: 'Reparații termopane pentru a păstra căldura în locuința ta.' },
      { id: "mentenanta-cladiri", name: "Mentenanta cladiri", description: "Mentenanta cladiri pentru a păstra cladirea in stare buna."},
      {
        id: "reparatii-usi-garaj",
        name: "Reparații uși garaj",
        description: "Reparații uși garaj pentru a păstra siguranța în locuința ta."
      },
      {
        id: "curatare-cosuri",
        name: "Curățare coșuri fum",
        description: "Curățare coșuri fum pentru a păstra aerul curat în locuința ta."
      },
      {
        id: "intretinere-centrale",
        name: "Întreținere centrale",
        description: "Întreținere centrale pentru a păstra centralele în stare buna."
      },
      {
        id: "reparatii-mobila",
        name: "Reparații mobila",
        description: "Reparații mobila pentru a păstra mobilierul in stare buna."
      },
      {
        id: "desfundare-canalizare",
        name: "Desfundare canalizare",
        description: "Desfundare canalizare pentru a păstra canalizarea in stare buna."
      },
      {
        id: "reparatii-electronice",
        name: "Reparații electronice",
        description: "Reparații electronice pentru a păstra echipamentele in stare buna."
      },
      {
        id: "reparatii-acoperis",
        name: "Reparații acoperișuri",
        description: "Reparații acoperișuri pentru a păstra acoperișul in stare buna."
      }
      
    ]
  }
];


const allTrades = serviceCategories.flatMap(category => 
  category.subcategories.map(subcat => subcat.name)
);
console.log(allTrades);

// Adăugăm mapping-ul pentru meserii
const tradesMapping = {
  // Renovări Interioare
  'zugrav': ['finisaje', [
    'zugraveala', 
    'vopsitorii-decorative', 
    'decorativa-interior',
    'decorativa-exterior',
    'finisaje-garduri'
  ]],
  'faiantar': ['renovari-interioare', ['gresie-faianta', 'placaje-marmura', 'piatra-naturala']],
  'parchetar': ['renovari-interioare', ['parchet', 'raschetare-parchet', 'reconditionare-parchet']],
  'tamplar': ['renovari-interioare', ['montaj-usi', 'reparatii-usi', 'reconditionare-usi', 'reparatii-mobila']],
  'rigipsar': ['renovari-interioare', ['rigips', 'tavane-false', 'pereti-despartitori']],
  
  // Instalații
  'instalator': ['instalatii', ['sanitare', 'termice', 'gaz', 'desfundare-canalizare']],
  'electrician': ['instalatii', ['electrice', 'automatizari', 'iluminat-exterior']],
  'instalator-centrale': ['instalatii', ['centrale-termice', 'pompe-caldura', 'panouri-solare']],
  'frigotehnist': ['instalatii', ['aer-conditionat', 'ventilatie']],
  
  // Construcții
  'zidar': ['constructii', ['zidarie', 'fundatii', 'tencuiala']],
  'dulgher': ['constructii', ['case-lemn', 'acoperisuri', 'structuri-metalice']],
  'fierar-betonist': ['constructii', ['fundatii', 'consolidari']],
  'demolator': ['constructii', ['demolari', 'consolidari']],
  'constructor': ['constructii', ['zidarie', 'fundatii', 'consolidari', 'case-lemn', 'acoperisuri']],
  
  // Amenajări Exterioare
  'peisagist': ['exterioare', ['gradinarit', 'peisagistica', 'irigatie']],
  'pavator': ['exterioare', ['pavaje', 'alei', 'garduri']],

  
  // Servicii Specializate
  'arhitect': ['servicii-specializate', ['proiectare', 'consultanta']],
  'firma-curatenie': ['servicii-specializate', ['curatenie']],
  'mentenanta-cladiri': ['servicii-specializate', ['mentenanta-cladiri']],
  
  // Reparații și Întreținere
  'service-centrale': ['reparatii-intretinere', ['intretinere-centrale', 'reparatii-centrale']],
  'service-termopane': ['reparatii-intretinere', ['reparatii-termopane']],
  'service-acoperisuri': ['reparatii-intretinere', ['reparatii-acoperisuri']],

};

// Funcție helper pentru a găsi joburile potrivite pentru un trade
const getJobsForTrade = (trade) => {
  const mapping = tradesMapping[trade];
  if (!mapping) return null;
  
  const [category, subcategories] = mapping;
  return {
    category,
    subcategories
  };
};

module.exports = {
  serviceCategories,
  allTrades,
  tradesMapping,
  getJobsForTrade
};
