import * as prismic from '@prismicio/client';

export const repositoryName = 'mesterlocal';

export const client = prismic.createClient(repositoryName, {
  accessToken: process.env.NEXT_PUBLIC_PRISMIC_ACCESS_TOKEN,
  routes: [
    {
      type: 'blog_post',
      path: '/blog/:uid'
    }
  ],
  defaultEndpoint: 'https://mesterlocal.cdn.prismic.io/api/v2'
}); 