import { unstable_cache } from 'next/cache';
import { cache } from 'react';

export const getCachedSession = unstable_cache(
  async (supabase) => {
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) throw error;
    return session;
  },
  ['user-session'],
  {
    revalidate: 3600, // Cache for 1 hour
    tags: ['session']
  }
);

export const getCachedMessages = unstable_cache(
  async (jobId, supabase) => {
    const { data: messagesData, error } = await supabase
      .from('messages')
      .select('*')
      .eq('job_id', jobId)
      .order('created_at', { ascending: true });

    if (error) throw error;
    return messagesData || [];
  },
  ['job-messages'],
  {
    revalidate: 60, // Cache for 1 minute
    tags: ['messages']
  }
);

export const getCachedJobDetails = unstable_cache(
  async (jobId, supabase) => {
    const { data, error } = await supabase
      .from('jobs')
      .select(`
        *,
        job_applications (
          worker_id,
          status
        )
      `)
      .eq('id', jobId)
      .single();

    if (error) throw error;
    return data;
  },
  ['job-details'],
  {
    revalidate: 300, // Cache for 5 minutes
    tags: ['job-details']
  }
);

export const getCachedConversations = cache(async (userId, supabase) => {
  try {
    console.log('Fetching conversations for user:', userId);

    // 1. Găsim toate job applications unde userul este implicat
    const { data: jobApplications, error: appsError } = await supabase
      .from('job_applications')
      .select(`
        job_id,
        worker_id,
        status,
        jobs!inner (
          id,
          title,
          status,
          client_id,
          budget
        )
      `)
     

    if (appsError) {
      console.error('Job applications error:', appsError);
      throw appsError;
    }

    console.log('Found job applications:', jobApplications);

    if (!jobApplications?.length) {
      console.log('No job applications found');
      return [];
    }

    // 2. Luăm toate mesajele pentru aceste job-uri
    const jobIds = jobApplications.map(app => app.job_id);
    
    const { data: messages, error: messagesError } = await supabase
      .from('messages')
      .select('*')
      .in('job_id', jobIds)
      .order('created_at', { ascending: false });

    if (messagesError) {
      console.error('Messages error:', messagesError);
      throw messagesError;
    }

    console.log('Found messages:', messages);

    if (!messages?.length) {
      console.log('No messages found');
      return [];
    }

    // 3. Grupăm mesajele după job_id și luăm ultimul mesaj pentru fiecare job
    const messagesByJob = {};
    messages.forEach(message => {
      if (!messagesByJob[message.job_id] || 
          new Date(message.created_at) > new Date(messagesByJob[message.job_id].created_at)) {
        messagesByJob[message.job_id] = message;
      }
    });

    // 4. Construim conversațiile finale
    const conversations = jobApplications
      .map(app => {
        const lastMessage = messagesByJob[app.job_id];
        if (!lastMessage) return null;

        return {
          job_id: app.job_id,
          jobs: app.jobs,
          worker_id: app.worker_id,
          last_message_at: lastMessage.created_at
        };
      })
      .filter(Boolean) // Eliminăm null-urile
      .sort((a, b) => new Date(b.last_message_at) - new Date(a.last_message_at));

    console.log('Final conversations:', conversations);
    return conversations;

  } catch (error) {
    console.error('Error in getCachedConversations:', error);
    return [];
  }
});

export const getCachedUnreadCounts = cache(async (userId, supabase) => {
  try {
    console.log('Fetching unread counts for user:', userId);

    const { data, error } = await supabase
      .from('messages')
      .select('job_id')
      .eq('is_read', false)
      .neq('sender_id', userId)
      .eq('worker_id', userId);

    if (error) throw error;

    console.log('Unread messages:', data);

    // Grupăm și numărăm
    const counts = {};
    (data || []).forEach(msg => {
      counts[msg.job_id] = (counts[msg.job_id] || 0) + 1;
    });

    return Object.entries(counts).map(([job_id, count]) => ({
      job_id,
      count
    }));

  } catch (error) {
    console.error('Error in getCachedUnreadCounts:', error);
    return [];
  }
});

export const getCachedNotifications = cache(async (userId, supabase) => {
  const { data, error } = await supabase
    .from('notifications')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching notifications:', error);
    return [];
  }

  return data || [];
});

export const getCachedUnreadNotificationsCount = cache(async (userId, supabase) => {
  const { data, error } = await supabase
    .from('notifications')
    .select('id', { count: 'exact' })
    .eq('user_id', userId)
    .eq('is_read', false);

  if (error) {
    console.error('Error fetching unread notifications count:', error);
    return 0;
  }

  return data?.length || 0;
});

export const getCachedJobMessages = cache(async (jobId, supabase) => {
  const { data, error } = await supabase
    .from('messages')
    .select('*')
    .eq('job_id', jobId)
    .order('created_at', { ascending: true });

  if (error) {
    console.error('Error fetching messages:', error);
    return [];
  }

  return data || [];
});

export const getCachedJobApplication = cache(async (jobId, workerId, supabase) => {
  const { data, error } = await supabase
    .from('job_applications')
    .select('*')
    .eq('job_id', jobId)
    .eq('worker_id', workerId)
    .single();

  if (error) {
    console.error('Error fetching job application:', error);
    return null;
  }

  return data;
});

export const getCachedWorkerProfile = cache(async (userId, supabase) => {
  const [
    { data: profile },
    { data: reviews },
    { data: completedJobs },
    { data: totalApplications },
    { data: portfolioImages }
  ] = await Promise.all([
    // Profile data
    supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
      .single(),
    
    // Reviews
    supabase
      .from('reviews')
      .select(`
        id,
        rating,
        comment,
        created_at,
        job:jobs(id, title),
        client:profiles!reviews_client_id_fkey(id, name)
      `)
      .eq('worker_id', userId),
    
    // Completed jobs
    supabase
      .from('job_applications')
      .select('job_id')
      .eq('worker_id', userId)
      .eq('status', 'completed'),
    
    // Total applications
    supabase
      .from('job_applications')
      .select('job_id')
      .eq('worker_id', userId),
    
    // Portfolio images
    supabase
      .from('portfolio_images')
      .select('image_name')
      .eq('user_id', userId)
  ]);

  const averageRating = reviews?.length > 0
    ? reviews.reduce((sum, review) => sum + (review.rating || 0), 0) / reviews.length
    : 0;

  const portfolioItems = portfolioImages?.map(image => ({
    name: image.image_name,
    url: supabase.storage.from('portfolio').getPublicUrl(image.image_name).data.publicUrl,
  })) || [];

  return {
    ...profile,
    reviews,
    averageRating,
    completedJobs: completedJobs?.length || 0,
    totalApplications: totalApplications?.length || 0,
    portfolio: portfolioItems
  };
});